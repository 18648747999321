<template>
  <a-modal
    :visible="visible"
    :title="isUpdate ? '修改平台信息' : '创建平台信息'"
    width="40%"
    centered
    okText="确认"
    cancelText="取消"
    :destroyOnClose="true"
    :maskClosable="false"
    @ok="onSubmit"
    @cancel="hideDialog"
  >
    <a-skeleton v-if="loading" active />
    <a-form-model v-else layout="vertical" :rules="rules" :model="platform" ref="form">
      <a-form-model-item label="平台ID" prop="id">
        <a-input v-model="platform.id" :disabled="isUpdate" placeholder="请输入平台ID" />
      </a-form-model-item>
      <a-form-model-item label="平台名称" prop="name">
        <a-input v-model="platform.name" placeholder="请输入平台名称" />
      </a-form-model-item>
      <a-form-model-item label="发布区域" prop="region.identifier">
        <a-input
          read-only
          :value="platform.region.identifier"
          @click="handleRegionSelect"
          placeholder="请选择发布区域"
        >
          <a-tooltip slot="suffix" title="Extra information">
            <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </a-input>
      </a-form-model-item>
      <template v-if="isUpdate">
        <a-form-model-item label="平台私钥键" prop="key">
          <a-input disabled v-model="platform.key" />
        </a-form-model-item>
        <a-form-model-item label="平台私钥值" prop="secret">
          <a-button v-if="!platform.secret" type="link" icon="eye" @click="fetchPlatformSecret">
          </a-button>
          <a-input v-else disabled v-model="platform.secret" />
        </a-form-model-item>
      </template>

      <a-form-model-item label="描述" prop="description">
        <a-textarea v-model="platform.description" placeholder="请输入描述" showCount />
      </a-form-model-item>
    </a-form-model>
    <region-select-modal ref="regionSelectModal" />
  </a-modal>
</template>

<script>
import RegionSelectModal from '../../region/components/RegionSelectModal';

export default {
  name: 'platformFormModal',
  components: { RegionSelectModal },
  data() {
    return {
      visible: false,
      isUpdate: false,
      loading: false,
      platform: {
        name: undefined,
        key: undefined,
        secret: undefined,
        description: undefined,
        regionIdentifier: '',
        region: {
          identifier: '',
          name: '',
        },
      },
      callback: undefined,
      rules: {
          id: [{ required: true, message: '平台ID 不能为空', trigger: 'blur' }],
          name: [{ required: true, message: '平台名称 不能为空', trigger: 'blur' }],
          'region.identifier': [{ required: true, message: '发布区域 不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          const regex = /^[a-zA-Z0-9_-]+$/;
          if (!regex.test(this.platform.id)) {
            this.$message.info('平台ID只能由大小写英文、数字、横杠和下划线组成');
            return;
          }
          if (this.callback) {
            this.callback(this.platform);
          }
        }
      });
    },
    hideDialog() {
      this.visible = false;
      this.isUpdate = false;
      this.callback = undefined;
      this.platform = {
        name: undefined,
        key: undefined,
        secret: undefined,
        description: undefined,
        regionIdentifier: '',
        region: {
          name: '',
          identifier: '',
        },
      };
    },
    showCreateDialog(cb) {
      this.callback = cb;
      this.isUpdate = false;
      this.visible = true;
    },
    async showUpdateDialog(platform, cb) {
      this.visible = true;
      this.loading = true;
      await this.fetchPlatformById(platform.id);
      if (platform.secret) {
        this.platform.secret = platform.secret;
      }
      this.callback = cb;
      this.isUpdate = true;
      this.loading = false;
    },
    async fetchPlatformById(id) {
      const res = await this.$apiManager.platform.getPlatformsById(id);
      Object.assign(this.platform, res.data);
    },
    handleRegionSelect() {
      const toDO = (select) => {
        this.platform.regionIdentifier = select[0];
        this.platform.region.identifier = select[0];
      };
      this.$refs.regionSelectModal.selectedRowKeys = [this.platform.regionIdentifier];
      this.$refs.regionSelectModal.showCreateDialog(toDO, 'radio');
    },
    async fetchPlatformSecret() {
      const res = await this.$apiManager.platform.getSecret(this.platform.id);
      this.platform.secret = res.data;
    },
  },
};
</script>

<style scoped lang="less"></style>
